import * as typeformEmbed from '@typeform/embed';
import React from 'react';
import styled from 'styled-components';

class TypeformEmbed extends React.Component {
  constructor(props) {
    super(props);
    this.embedRef = React.createRef();
  }

  componentDidMount = () => {
    typeformEmbed.makeWidget(this.embedRef.current, this.props.typeformUrl, {
      hideFooter: true,
      hideHeaders: true,
      opacity: 0,
      onSubmit: this.props.onSubmit,
    });
  };

  componentWillUnmount() {
    // Typeform adds overflow and padding styling to the HTML element, let's make sure we remove it.
    let htmlElement = document.getElementsByTagName('html')[0];
    htmlElement.style = {};
    const elements = document.getElementsByClassName('__typeform-embed-mobile-modal-open');
    for (let index = 0; index < elements.length; index++) {
      elements[index].classList = [];
    }
  }

  render() {
    return <div className={this.props.className} ref={this.embedRef}></div>;
  }
}

TypeformEmbed.defaultProps = {
  typeformUrl: undefined,
  onSubmit: () => {}, // No-op
};

TypeformEmbed = styled(TypeformEmbed)`
  height: 70vh;
`;

export default TypeformEmbed;
