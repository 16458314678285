import { media } from '@helpers/media_templates';
import { COLORS } from '@helpers/style_constants';
import * as React from 'react';
import styled from 'styled-components';

const PanelWrapper = styled.div`
  height: 100%;

  background: linear-gradient(180deg, ${props => props.topColor} 0%, ${props => props.bottomColor} 110%);
  padding: 5px;
  grid-column: 1 / -1;

  ${media.large`
    grid-column: span 4;
    margin-bottom: 0;
  `}
`;

const PanelInner = styled.div`
  background: ${COLORS.LightGrey};
  height: 100%;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;

  ${media.large`
    padding: 30px;
  `}

  h3 {
    margin: 0 0 10px;
  }

  p {
    margin: 0;
  }
`;

export const Panel = ({ children, ...otherProps }) => {
  return (
    <PanelWrapper {...otherProps}>
      <PanelInner>{children}</PanelInner>
    </PanelWrapper>
  );
};
