import { media } from '@helpers/media_templates';
import styled from 'styled-components';

export const GUTTER_SIZES = {
  small: '20px',
  medium: '20px',
  large: '32px',
};

export const COLUMN_COUNT = {
  small: 4,
  medium: 6,
  large: 12,
};

export const COLUMN_WIDTH = {
  small: '1fr',
  medium: '1fr',
  large: '1fr',
};

/*
  This is kind of confusing but basically it sets up the top-level grid using the maps defined above. You shouldn't
  be using this for "interior" grids, which should be simpler and just use the GUTTER_SIZES for `grid-column-gap`
  It also defines lines which have 5 basic forms:
    - 'start' is the left most side of the grid, extending to the very edge of the window
    - 'end' is the same, for the right side
    - 'col N' lets you refer to the beginning of any individual column
    - 'col-end N' lets you refer to the end of any individual column
    - 'gutter-right-start' is the end of column section, but before the right most gutter. This lets you refer to this
      point without worrying about the number of columns currently being used.
*/
export const makeGrid = Component => styled(Component)`
  display: grid;
  width: 100%;
  justify-content: stretch;
  align-items: center;
  grid-template-rows: auto;
  grid-row-gap: 0px;
  grid-auto-flow: row;
  overflow: visible;

  grid-column-gap: ${GUTTER_SIZES.small};
  grid-template-columns:
    [start]
    repeat(${COLUMN_COUNT.small}, [col] ${COLUMN_WIDTH.small} [col-end])
    [end];

  ${media.medium`
    grid-column-gap: ${GUTTER_SIZES.medium};
    grid-template-columns:
      [start]
      repeat(${COLUMN_COUNT.medium}, [col] ${COLUMN_WIDTH.medium} [col-end])
      [end];
  `}

  ${media.large`
    grid-column-gap: ${GUTTER_SIZES.large};
    grid-template-columns:
      [start]
      repeat(${COLUMN_COUNT.large}, [col] ${COLUMN_WIDTH.large} [col-end])
      [end];
  `}
`;

export const StandardGrid = makeGrid('div');
