import React from 'react';
import styled from 'styled-components';

export const Input = styled.input`
  max-width: 25px;
  width: 25px;
  height: 25px;

  box-shadow: ${props => (!!props.error ? '0 0 10px red' : '')};
`;

const CheckboxInput = props => {
  const { name, error, ...rest } = props;

  // TODO (maybe): Display error message
  return <Input type="checkbox" name={name} error={error} {...rest} />;
};

export default CheckboxInput;
