import { Input } from '@cheese-fondue/components/atoms/Input/input';
import React from 'react';
import styled from 'styled-components';

export const EMAIL_PATTERN = '[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.([xX][nN]--[-A-Za-z0-9]+|[A-Za-z]{2,})$';

export const ErrorText = styled.span``;

const TextInput = props => {
  const {
    onChange,
    onBlur,
    name, // Field identifier for onChange callback
    error,
    ...rest
  } = props;

  const handleChange = event => {
    if (!!onChange) {
      onChange({
        [name]: event.target.value,
      });
    }
  };

  const handleBlur = event => {
    if (!!onBlur) {
      onBlur({
        [name]: event.target.value,
      });
    }
  };

  // TODO (maybe): Display error message
  return <Input onChange={handleChange} onBlur={handleBlur} error={error} {...rest} />;
};

export default TextInput;
