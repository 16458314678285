import { Link as GatsbyLink } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

// Meant to supplant the Gatsby Link component, so that we can use the same component regardless of whether it's
// external or internal
const Link = ({ children, to, activeClassName, partiallyActive, className, ...other }) => {
  // This example assumes that any internal link will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);
  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        className={className}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  } else {
    let aClassName = className;
    if (typeof window !== 'undefined' && window.location === to) {
      aClassName += ' ' + activeClassName;
    }
    return (
      <a href={to} className={aClassName} {...other}>
        {children}
      </a>
    );
  }
};

export const NavLink = styled(Link).attrs({
  activeClassName: 'currentPage',
})`
  line-height: 1.4;
  font-size: 23px;
  text-decoration: none;

  color: ${props => (props.isCurrent ? props.theme.color : props.theme.nav.color)};

  &:hover {
    color: ${props => props.theme.nav.hover};
  }

  &.currentPage {
    color: ${props => props.theme.color};
    text-decoration: none;
  }
`;

export default Link;
