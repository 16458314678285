import styled from 'styled-components';

export const makeScreenReaderOnly = Component => styled(Component)`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`;

export const ScreenReaderLabel = makeScreenReaderOnly('span');
