import { media } from '@helpers/media_templates';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const defaultGridColumns = `
  grid-column-start: 1;
  grid-column-end: -1;
`;

function getColumnCss(sizedCellSpec) {
  const startingValue = sizedCellSpec.small === undefined ? defaultGridColumns : ``;

  return Object.keys(sizedCellSpec).reduce((acc, sizeName) => {
    if (media[sizeName] !== undefined) {
      return css`
        ${acc}
        ${media[sizeName]`
            grid-column-start: ${sizedCellSpec[sizeName].start};
            grid-column-end: ${sizedCellSpec[sizeName].end};
          `}
      `;
    } else {
      return css`
        ${acc}
        ${media.customBreak(sizeName)`
          grid-column-start: ${sizedCellSpec[sizeName].start};
          grid-column-end: ${sizedCellSpec[sizeName].end};
        `}
      `;
    }
  }, startingValue);
}

export const makeCell = Component => {
  return styled(Component)`
    position: relative;

    ${props => (props.columns ? getColumnCss(props.columns) : defaultGridColumns)}
    ${props => (props.row ? `grid-row: ${props.row};` : ``)}
  ${props => (props.area ? `grid-area: ${props.area};` : ``)}
  `;
};

export const Cell = makeCell('div');

const CellSpec = PropTypes.shape({
  start: PropTypes.number.isRequired,
  end: PropTypes.number.isRequired,
});

Cell.propTypes = {
  columns: PropTypes.shape({
    small: CellSpec,
    medium: CellSpec,
    large: CellSpec,
  }),
  row: PropTypes.string,
  area: PropTypes.string,
};

export const SectionCell = styled.section`
  position: relative;
  min-width: 0;
  min-height: 0;
  width: 100%;
  grid-row: ${props => props.row};
`;
