import { Cell } from '@components/grid';
import { media } from '@helpers/media_templates';
import BackgroundImage from 'gatsby-background-image';
import * as React from 'react';
import styled from 'styled-components';

const StyledBackgroundImage = styled(BackgroundImage)`
  height: 100%;
`;

export const GradientImg = ({ color, img, ...rest }) => {
  const imgStack = [`linear-gradient(180deg, ${`${color}00`} 50%, ${color} 110%)`, img];
  return <StyledBackgroundImage fluid={imgStack} {...rest} />;
};

export let GradientImgWrapper = ({ img, color, ...otherProps }) => {
  return (
    <Cell {...otherProps}>
      <GradientImg img={img} color={color} />
    </Cell>
  );
};

GradientImgWrapper = styled(GradientImgWrapper)`
  height: 100%;
  width: calc(100% - 20px);

  ${media.medium`
    width: 100%;
  `}
`;

export const GradientBackground = styled(Cell)`
  width: 100%;
  height: 100%;
  content: ' ';
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, ${props => props.color} 100%);
`;
