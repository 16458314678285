import { media } from '@helpers/media_templates';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import styled from 'styled-components';

/** Unfortunately graphql cannot do string template substitutions **/
export const IMAGE_QUERY = graphql`
  query {
    techCrunchLogo: file(relativePath: { eq: "logos/techcrunch-logo.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    globeAndMailLogo: file(relativePath: { eq: "logos/the-globe-and-mail-vector-logo.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    forbesLogo: file(relativePath: { eq: "logos/forbes-logo.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    fastCompanyLogo: file(relativePath: { eq: "logos/fastco.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    CBCLogo: file(relativePath: { eq: "logos/cbc-logo-horizontal.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
    PHLogo: file(relativePath: { eq: "logos/ph-logo.png" }) {
      childImageSharp {
        fixed {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const ARTICLES = [
  {
    logoName: 'techCrunchLogo',
    link:
      'https://techcrunch.com/2019/12/04/delphia-wants-to-turn-your-data-into-investment-capital-through-collective-action/',
  },
  {
    logoName: 'globeAndMailLogo',
    link:
      'https://www.theglobeandmail.com/business/article-toronto-startup-delphia-wants-to-pay-consumers-to-share-their-data/',
  },
  {
    logoName: 'forbesLogo',
    link:
      'https://www.forbes.com/sites/annefield/2019/12/11/with-this-platform-regular-folks-reap-benefits-from-their-data-and-so-do-individual-investors/#7fa3e1d425ee',
  },
  {
    logoName: 'fastCompanyLogo',
    link:
      'https://www.fastcompany.com/90438328/this-startup-wants-to-use-your-data-to-bet-on-the-market-and-cut-you-in-on-the-profits',
  },
  {
    logoName: 'CBCLogo',
    link:
      'https://www.cbc.ca/listen/live-radio/1-39-metro-morning/clip/15750654-toronto-startup-wants-to-use-your-data-to-make-investment-decisions-and-will-pay-for-it',
  },
  {
    logoName: 'PHLogo',
    link: 'https://www.producthunt.com/posts/delphia',
  },
];

const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  padding-top: 20px;

  ${media.medium`
    flex-direction: row;
    padding: 50px 0;
  `}
`;

const LogoContainer = styled.div`
  padding: 15px;

  ${media.large`
    padding: 25px;
  `}
`;

const ArticleLink = styled.a`
  padding: 10px;
`;

const Logo = ({ article_data }) => {
  const data = useStaticQuery(IMAGE_QUERY);
  return (
    <LogoContainer>
      <ArticleLink href={article_data.link}>
        <Img fixed={data[article_data.logoName].childImageSharp.fixed} alt={article_data.link} />
      </ArticleLink>
    </LogoContainer>
  );
};

export default () => {
  return (
    <Row>
      {ARTICLES.map((article, i) => (
        <Logo key={`logo_${i}`} article_data={article} />
      ))}
    </Row>
  );
};
