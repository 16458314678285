import { Cell } from '@components/grid';
import { media } from '@helpers/media_templates';
import styled from 'styled-components';

export const Rule = styled(Cell)`
  width: 100%;
  background: black;
  height: 5px;
  border: 0;
  display: inline-block;
`;

export const MediumVisibleRule = styled(Rule)`
  display: none;

  ${media.medium`
    display: inline-block;
  `}
`;
