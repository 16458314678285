import { SmallButton } from '@components/core';
import { media } from '@helpers/media_templates';
import { navigate } from 'gatsby';
import * as React from 'react';
import styled from 'styled-components';

const MinorHeader = styled.h3`
  display: inline-block;
  font-family: primary-mono;
  font-size: 18px;
  grid-column: 1 / -1;

  ${media.large`
    font-size: 22px;
  `}
  margin: 20px 0 0 0;
`;

export const EditButton = props => {
  const { onClick } = props;
  return (
    <SmallButton type="button" onClick={onClick}>
      Edit
    </SmallButton>
  );
};

export const SummaryForEdit = props => {
  const { editLink, editState, editFunc, title, summary } = props;
  let onEdit;
  if (editLink) {
    onEdit = () => navigate(editLink, { state: editState });
  } else if (editFunc) {
    onEdit = editFunc;
  }
  return (
    <>
      <MinorHeader>
        {title}
        {onEdit && <EditButton onClick={onEdit} />}
      </MinorHeader>
      <dl>
        {Object.entries(summary).map(([field, values]) => {
          if (!Array.isArray(values)) {
            values = [values];
          }
          return (
            <React.Fragment key={field}>
              <dt>
                <strong>{field}</strong>
              </dt>
              {values.map((value, index) => (
                <dd key={index}>{value}</dd>
              ))}
            </React.Fragment>
          );
        })}
      </dl>
    </>
  );
};
