export const COLORS = {
  Aqua: '#85E0D4',
  AquaAreaBackground: '#E1F7F4',
  Black: '#000000',
  BloodOrange: '#FF877D',
  BloodOrangeAreaBackground: '#FFE1DF',
  Daisy: '#FFD954',
  DaisyAreaBackground: '#FFF6D4',
  Orchid: '#E091FF',
  Peach: '#FFBD8C',
  Purple: '#6F29F0',
  Teal: '#2DBED2',
  LighterPurple: '#884FF3',
  LightPurple: '#D5C3F5',
  White: '#FFFFFF',
  LightGrey: '#F7F7F7',
  RailGrey: '#E9E9E9',
  MidGrey: '#E2E2E2',
  DarkGrey: '#848484',
  DarkBlue: '#0571B0',
  LightBlue: '#79ADCB',
};

export const THEME = {
  backgroundColor: COLORS.White,
  color: COLORS.Black,
  primary: COLORS.Aqua,
  secondary: COLORS.BloodOrange,
  button: {
    color: COLORS.White,
    background: COLORS.Purple,
    hover: COLORS.LighterPurple,
    active: COLORS.Purple,
    disabled: COLORS.DarkGrey,
  },
  nav: {
    color: COLORS.DarkGrey,
    hover: COLORS.Black,
    iconColor: COLORS.Black,
  },
  body: COLORS.LightGrey,
  footer: COLORS.White,
  footerText: COLORS.DarkGrey,
  logoIcon: COLORS.Black,
  modal: {
    background: COLORS.LightGrey,
    color: COLORS.Black,
  },
};
