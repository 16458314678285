import styled from 'styled-components';

interface ButtonProps {
  activeBackground?: string;
  background?: string;
  color?: string;
  disabled?: boolean;
  disabledBackground?: string;
  hoverBackground?: string;
}

export const Button = styled.button<ButtonProps>`
  display: inline-block;
  background: ${props => props.background || props.theme.button.background};
  color: ${props => props.color || props.theme.button.color};
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  padding: 16px 34px;
  line-height: 1.2;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: primary-bold;
  border: none;
  outline: none;

  :hover {
    background: ${props => props.hoverBackground || props.theme.button.hover};
  }

  :active {
    background: ${props => props.activeBackground || props.theme.button.active};
  }

  :disabled {
    background: ${props => props.disabledBackground || props.theme.button.disabled};
  }
`;

export const SmallButton = styled(Button)`
  margin: 10px;
  padding: 8px 18px;
  line-height: normal;
`;
