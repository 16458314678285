import { css, CSSObject } from 'styled-components';

const SIZES = {
  huge: 1600,
  large: 1280,
  medium: 680,
  small: 0,
};

interface MediaQueryFactory {
  (css: CSSObject | TemplateStringsArray): string;
}

interface MediaQueries {
  huge: MediaQueryFactory;
  large: MediaQueryFactory;
  medium: MediaQueryFactory;
  small: MediaQueryFactory;
}

// Iterate through the sizes and create a media template
// Example usage:
// const Content = styled.div`
//   height: 3em;
//   width: 3em;
//   background: papayawhip;

//   /* Now we have our methods on media and can use them instead of raw queries */
//   ${media.large`background: dodgerblue;`}
//   ${media.medium`background: mediumseagreen;`}
//   ${media.small`background: palevioletred;`}
// `;
let media: MediaQueries = Object.keys(SIZES).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${SIZES[label]}px) {
      ${css(...args)}
    }
  `;
  return acc;
}, {});

media.customBreak = function (size) {
  return (...args) => css`
    @media (min-width: ${size}px) {
      ${css(...args)}
    }
  `;
};

export { media, SIZES };
