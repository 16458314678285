import { COLORS } from '@helpers/style_constants';
import React from 'react';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  text-align: center;
`;

export const Spinner = () => (
  <Container>
    <Loader type="Oval" color={COLORS.DarkGrey} />
  </Container>
);
