import React from 'react';
import styled from 'styled-components';
import Link from './links';

export const ButtonLinkInner = styled.span`
  display: inline-block;
  background: ${props => props.background || props.theme.button.background};
  color: ${props => props.color || props.theme.button.color};
  box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  padding: 16px 34px;
  font-size: 14px;
  line-height: 1.2;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  font-family: primary-bold;
  text-align: center;
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;

  :hover {
    background: ${props => props.theme.button.hover};
  }

  :active {
    background: ${props => props.theme.button.active};
  }
`;

let ButtonLink = props => {
  const { to, children, className, ...buttonProps } = props;
  return (
    <Link to={to} className={className}>
      <ButtonLinkInner {...buttonProps}>{children}</ButtonLinkInner>
    </Link>
  );
};

ButtonLink = styled(ButtonLink)`
  display: inline-block;
`;

export { ButtonLink };
